<template>
  <main
    id="guest-app"
    class="min-h-[100dvh]"
    :style="cssVariables"
    :data-page="route.name"
  >
    <slot />

    <AppStyleTag :content="customFontCss" />
  </main>
</template>

<script setup>
import Color from 'color';

// Computed
const route = useRoute();
const title = computed(() => {
  if (useGuestAppStore().guest?.properties?.lang) {
    const titleSuffix = useGuestAppStore().event?.guestApp?.[`_${useGuestAppStore().guest?.properties?.lang}`]?.eventTitle || 'Attendu';

    if (route?.meta?.title) {
      return `${typeof route.meta.title === 'function' ? route.meta.title() : route.meta.title} | ${titleSuffix}`;
    }

    return titleSuffix;
  }

  return 'Attendu';
});

const cssVariables = computed(() => {
  let primaryColor = '#000000';
  try {
    primaryColor = Color(useGuestAppStore().event?.guestApp?.primaryColor || useWorkspaceStore().identity?.primaryColor || '#19A43A').hex();
  } catch (error) {
    console.error('Invalid primaryColor:', primaryColor, error);
  }

  return [
    `--color-primary-50: ${Color(primaryColor).mix(Color('#fff'), 0.96).hex()}`,
    `--color-primary-100: ${Color(primaryColor).mix(Color('#fff'), 0.88).hex()}`,
    `--color-primary-200: ${Color(primaryColor).mix(Color('#fff'), 0.66).hex()}`,
    `--color-primary-300: ${Color(primaryColor).mix(Color('#fff'), 0.44).hex()}`,
    `--color-primary-400: ${Color(primaryColor).mix(Color('#fff'), 0.22).hex()}`,
    `--color-primary-500: ${Color(primaryColor).hex()}`,
    `--color-primary-500/15: ${Color(primaryColor).fade(0.85).string()}`,
    `--color-primary-500-15: ${Color(primaryColor).fade(0.85).string()}`,
    `--color-primary-600: ${Color(primaryColor).mix(Color('#000'), 0.22).hex()}`,
    `--color-primary-700: ${Color(primaryColor).mix(Color('#000'), 0.44).hex()}`,
    `--color-primary-800: ${Color(primaryColor).mix(Color('#000'), 0.66).hex()}`,
    `--color-primary-900: ${Color(primaryColor).mix(Color('#000'), 0.88).hex()}`,
    `--el-color-primary: ${Color(primaryColor).hex()}`,
  ];
});

const customFontCss = computed(() => {
  if (useGuestAppStore().event?.guestApp?.font) {
    return `
      @font-face {
        font-family: 'CustomAppFont';
        font-style: normal;
        font-weight: 400;
        src: url('${useGuestAppStore().event?.guestApp?.font.regular}') format('woff');
      }
      @font-face {
        font-family: 'CustomAppFont';
        font-style: normal;
        font-weight: 500;
        src: url('${useGuestAppStore().event?.guestApp?.font.medium}') format('woff');
      }
      @font-face {
        font-family: 'CustomAppFont';
        font-style: normal;
        font-weight: 600;
        src: url('${useGuestAppStore().event?.guestApp?.font.bold}') format('woff');
      }
      @font-face {
        font-family: 'CustomAppFont';
        font-style: normal;
        font-weight: 700;
        src: url('${useGuestAppStore().event?.guestApp?.font.bold}') format('woff');
      }`;
  }
  return '';
});

// Methods
const renderCustomScriptTags = async() => {
  const scripts = (useGuestAppStore().event?.guestApp?.customScriptTags || '').match(/<script[^>]*>[\s\S]*?<\/script>/g);
  if (scripts) {
    document.querySelectorAll('[data-custom-script]').forEach((script) => script.remove());

    scripts.forEach((script) => {
      // if script has src attribute, load it
      if (script.match(/src="[^"]*"/)) {
        const src = script.match(/src="([^"]*)"/)[1];
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('data-custom-script', '');
        scriptElement.src = src;
        document.body.appendChild(scriptElement);
      } else {
        // if script has no src attribute, execute it
        const scriptContent = script.replace(/<script[^>]*>/, '').replace(/<\/script>/, '');
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('data-custom-script', '');
        scriptElement.innerHTML = `(function() { ${scriptContent} })();`;
        document.body.appendChild(scriptElement);
      }
    });
  }
};


// Watch
watch(() => useGuestAppStore().event?.guestApp?.customScriptTags, () => {
  renderCustomScriptTags();
});

useSeoMeta({
  title,
  ogTitle: title.value && title, // title.value here is hackfix
});

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});
</script>

<style>
#guest-app {
  @apply font-app;

  .container {
    @apply mx-auto max-w-[70.25rem] sm:px-10;
  }
}
</style>
